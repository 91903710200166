@use "../styles/mixins.scss" as mixins;

.table {
  margin: 0 -20px;
  width: auto !important;
  @include mixins.sm {
    margin: 0;
    width: 100% !important;
  }
  th,
  td {
    .size-full {
      display: none;
    }
    .size-sm {
      display: block;
    }
    @include mixins.sm {
      .size-full {
        display: block;
      }
      .size-sm {
        display: none;
      }
    }
  }
  &.round {
    &__table {
      th {
        padding: 0 6px;
        line-height: 32px;
        border: none;
        background: #eee;
        &.round {
          &__rank {
            text-align: center;
            font-weight: bold;
            font-size: 14;
            color: #666;
            // span {
            //   display: none;
            //   @include sm {
            //     display: inline-block;
            //   }
            // }
          }
          &__ace,
          &__score,
          &__par {
            text-align: center;
          }
          &__credit {
            white-space: nowrap;
            text-align: center;
          }
        }
      }
      tr > * {
        @include mixins.sm {
          //  width: auto !important;
        }
      }
      td {
        &.round {
          &__rank {
            text-align: center;
            padding: inherit 4px;
            font-size: 10px;
            color: #666;
            vertical-align: middle;
            @include mixins.sm {
              width: 40px;
            }
          }
          &__ace,
          &__score,
          &__par {
            width: 50px;
            text-align: center;
          }
          &__payout {
            width: 60px;
          }
          &__credit {
            width: 64px;
            text-align: center;
          }
        }
      }
    }
  }
}
.round {
  &__player__pdga {
    display: inline-block;
    padding-left: 10px;
    font-size: 0.8em;
  }
}
// .header__division {
//   font-weight: bold;
//   font-size: 20px;
//   @include sm {
//     display: none;
//   }
// }
