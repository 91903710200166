@use "./mixins.scss" as mixins;

.container,
.container-md,
.container-sm {
  max-width: 100%;
}

.content {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  @include mixins.sm {
    padding: 20px;
  }
}
#admin {
  .content {
    max-width: 100%;
  }
}

.table-wrapper {
  margin: 0 -20px;
  @include mixins.md {
    margin: 0;
  }
}

.clickable {
  color: #047bfe;
  cursor: pointer;
  text-decoration: underline;

  &.active {
    font-weight: bold;
    text-decoration: none;
  }
}

.font-sm {
  font-size: 0.85em;
}
.title {
  text-transform: capitalize;
}

.align-items-right {
  display: flex;
  justify-content: flex-end;
}

.push-right {
  margin-left: auto;
}

h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.3em;
}
h4 {
  font-size: 1.2em;
}
h5 {
  font-size: 1.1em;
}

// dynamic classes for padding and margin...
// top, bottom, left, right
// m-1, m-2, m-3, m-4, m-5
// p-1, p-2, p-3, p-4, p-5
// mb-1, mb-2, mb-3, mb-4, mb-5
// mt-1, mt-2, mt-3, mt-4, mt-5
// ml-1, ml-2, ml-3, ml-4, ml-5
// mr-1, mr-2, mr-3, mr-4, mr-5
// pb-1, pb-2, pb-3, pb-4, pb-5
// pt-1, pt-2, pt-3, pt-4, pt-5
// pl-1, pl-2, pl-3, pl-4, pl-5
// pr-1, pr-2, pr-3, pr-4, pr-5
// 1 = .5em
// 2 = 1em
// 3 = 1.5em
// 4 = 2em
// 5 = 2.5em
#root {
  @for $i from 0 through 5 {
    .margin-#{$i} {
      margin: #{$i * 0.5}em;
    }
    .mb-#{$i} {
      margin-bottom: #{$i * 0.5}em;
    }
    .mt-#{$i} {
      margin-top: #{$i * 0.5}em;
    }
    .ml-#{$i} {
      margin-left: #{$i * 0.5}em;
    }
    .mr-#{$i} {
      margin-right: #{$i * 0.5}em;
    }
    .pad-#{$i} {
      padding: #{$i * 0.5}em;
    }
    .pb-#{$i} {
      padding-bottom: #{$i * 0.5}em;
    }
    .pt-#{$i} {
      padding-top: #{$i * 0.5}em;
    }
    .pl-#{$i} {
      padding-left: #{$i * 0.5}em;
    }
    .pr-#{$i} {
      padding-right: #{$i * 0.5}em;
    }
  }
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
