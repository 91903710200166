@use "../../styles/mixins.scss" as mixins;

#subtract {
  background: #fdcece;
}
#add {
  background: #cefdd9;
}

#entries {
  margin: 1em -20px;
  @include mixins.md {
    margin: 1em 0;
  }
  .divisonGroup {
    h5 {
      padding: 10px 0 5px;
      position: relative;
      margin-left: 2em;
      @include mixins.md {
        margin: 0;
      }
      em {
        color: #999;
        font-size: 12px;
        position: absolute;
        margin: 2px 6px 0;
      }
    }
  }
}
table.entry {
  margin: 0;
  width: 100%;

  &.scoring {
    width: auto;
  }
  th {
    padding: 0 6px;
  }
  td {
    height: 30px;
    padding: 0 6px;
    font-size: 0.9em;
    @include mixins.md {
      font-size: 1em;
    }
  }

  tbody tr:nth-child(odd) td {
    background-color: #eee;
  }

  .name {
    width: 30%;
  }
  .pos,
  .ace,
  .score {
    text-align: center;
    width: 60px;
    input {
      width: 60px;
    }
  }
  .pos {
    color: #aaa;
  }
  .credit-added {
    color: darkgreen;
  }
  .credit-used {
    color: darkred;
  }
  .pdga {
    font-size: 0.8em;
  }
}

#credit_added {
  border: darkgreen solid 1px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(46, 255, 60, 0.9);
  -moz-box-shadow: 0px 0px 5px 0px rgba(46, 255, 60, 0.9);
  box-shadow: 0px 0px 5px 0px rgba(46, 255, 60, 0.9);
}
#credit_used {
  border: darkred solid 1px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(255, 46, 46, 0.9);
  -moz-box-shadow: 0px 0px 5px 0px rgba(255, 46, 46, 0.9);
  box-shadow: 0px 0px 5px 0px rgba(255, 46, 46, 0.9);
}

.scoresLink {
  position: absolute;
  right: 20px;
}

.scoring__wrapper {
  display: flex;
}

input.import {
  width: 60px;
  // background:rgba(207, 248, 209, 0.9);
  border: solid 1px #777;
}

input.import:invalid {
  background-color: #ffaaaa;
}

input.import:valid {
  background-color: #aaffaa;
}

#import {
  height: 60%;
  width: 100%;
}
#missing {
  width: 100%;
  height: 150px;
}
.importColumn {
  display: flex;
  flex-direction: column;
  width: 280px;
}
button.use-credit-button {
  display: block;
  width: 100%;
  margin-bottom: 6px;
}

.player-search-input__control,
.player-search-input__menu {
  z-index: 10 !important;
}
.credit-group {
  z-index: 1;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.input-btn {
  border-radius: 0 5px 5px 0 !important;
}
.form-group.flexRow {
  display: flex;
  gap: 20px;
  &.centered {
    align-items: center;
  }
}
.noWrap {
  white-space: nowrap;
}
