.checkbox-column {
  width: 32px;
  text-align: center;
  vertical-align: middle;
}
// @import "../styles/mixins.scss";

// .table.round {
//   &__table {
//     th {
//       padding: 0 6px;
//       line-height: 32px;
//       border: none;
//       background: #eee;
//       &.round {
//         &__rank {
//           font-weight: bold;
//           font-size: 20px;
//           // span {
//           //   display: none;
//           //   @include sm {
//           //     display: inline-block;
//           //   }
//           // }
//         }
//         &__credit {
//           white-space: nowrap;
//         }
//       }
//     }
//     tr > * {
//       @include sm {
//         //   width: auto !important;
//       }
//     }
//     td {
//       &.round {
//         &__rank {
//           text-align: center;
//           padding: inherit 4px;
//           @include sm {
//             width: 40px;
//           }
//         }
//         &__ace,
//         &__score,
//         &__par {
//           width: 50px;
//         }
//         &__credit {
//           width: 64px;
//           text-align: center;
//         }
//       }
//     }
//   }
// }
// .round {
//   &__player__pdga {
//     display: inline-block;
//     padding-left: 10px;
//     font-size: 0.8em;
//   }
// }

// table.table {
//   td {
//     vertical-align: middle;
//     &.table__center {
//       text-align: center;
//     }
//   }
// }

// // .header__division {
// //   font-weight: bold;
// //   font-size: 20px;
// //   @include sm {
// //     display: none;
// //   }
// // }
