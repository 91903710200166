.form-group {
  margin-bottom: 8px;
  button + button {
    margin-left: 10px;
  }
}
.form-label {
  margin-bottom: 0;
  color: #888;
}

.react-datepicker-wrapper {
  display: block;
}
.react-datepicker__triangle {
  transform: translate3d(50px, 0px, 0px) !important;
}

.form-group.checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;

  .form-check {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 30px;
    border: solid 1px #ccc;
    border-radius: 5px;
    &:hover,
    &:active {
      background: #eee;
      cursor: pointer;
    }
    label {
      flex-grow: 1;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
